
body{
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
h1, h2, h3, h4, h5, h6 {
    font-family: 'Archivo', sans-serif !important;
    font-weight: 600;
}
p{
    padding: 0;
    margin: 0;
}
