$main_color:#15151a;
$primary:#312883;
$light:green;

$secondary: rgb(255, 193, 7);

$dark:#191920;
$light:#20222d;
$textColor:#333;
$box:#000000;
// $highLightText:rgb(61, 195, 234);
$highLightText:#FCD419;
$darknessColor:#61656e;
$bgGrayColor:#ecece9;
$color:#fff;


//color combination
$color1:#51bfe6;
$color2:#b9d24e;
$color3:black;
$color4:white;
$color5:#FFC107;


@import "/node_modules/bootstrap/scss/bootstrap.scss";
// @import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');